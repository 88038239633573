import { HomeIcon, InformationCircleIcon, NewspaperIcon, VideoCameraIcon, TableIcon } from '@heroicons/react/outline';

export const links = [
  {
    name: 'Accueil',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Thèse',
    href: '/these/',
    icon: TableIcon,
  },
  {
    name: 'Écrits',
    href: '/ecrits/',
    icon: NewspaperIcon,
  },
  {
    name: 'Vidéos',
    href: '/videos/',
    icon: VideoCameraIcon,
  },
  {
    name: 'À propos',
    href: '/a-propos/',
    icon: InformationCircleIcon,
  },
];
